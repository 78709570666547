import React from 'react';
import { string, shape, arrayOf, func } from 'prop-types';

import classnames from 'classnames';
import Tag from '@andes/tag';

import { FilterLink } from '../../../components-v2/link';

const namespace = 'ui-search-applied-filters';

const AppliedFilters = ({ appliedFilters, className, onClose }) => (
  <div className={classnames(namespace, className)}>
    {appliedFilters.map((filter) =>
      filter.values.map((it) => (
        <FilterLink href={it.url} key={it.id} className={`${namespace}__tag`} filterId={filter.id} title={it.name}>
          <Tag label={it.name} onClose={onClose} />
        </FilterLink>
      )),
    )}
  </div>
);

AppliedFilters.propTypes = {
  appliedFilters: arrayOf(
    shape({
      id: string.isRequired,
      values: arrayOf(
        shape({
          id: string.isRequired,
          name: string.isRequired,
          url: string.isRequired,
        }),
      ),
    }),
  ),
  className: string,
  onClose: func,
};

AppliedFilters.defaultProps = {
  appliedFilters: [],
  className: '',
  onClose: () => {},
};

export default AppliedFilters;
